import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore"; 
import { db } from '../firebase/firestore';
import { IoCloseCircleSharp } from 'react-icons/io5';

const AdminSettings = () => {
  const [settings, setSettings] = useState({
    coolDownTime: 0,
    tappingGuru: 0,
  });
  
  const [dailyComboImages, setDailyComboImages] = useState(['', '', '', '']);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(''); // New state for user ID to delete

  useEffect(() => {
    fetchSettings();
    fetchDailyComboImages();
  }, []);

  const fetchSettings = async () => {
    const docRef = doc(db, "settings", "lrM3I4lW43z5QoqzEKtx");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setSettings(docSnap.data());
    } else {
      console.log("No such document!");
    }
  };

  const fetchDailyComboImages = async () => {
    const docRef = doc(db, 'DailyCombo', 'mia6x8Hb9656p8UQtdfL');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setDailyComboImages(data.correctImages || ['','','','']);
    } else {
      console.log("No such DailyCombo document!");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value === '' ? '' : Number(value) });
  };

  const handleImageChange = (index, value) => {
    const updatedImages = [...dailyComboImages];
    updatedImages[index] = value;
    setDailyComboImages(updatedImages);
  };

  const handleUpdateSettings = async () => {
    const docRef = doc(db, "settings", "lrM3I4lW43z5QoqzEKtx");
    try {
      await updateDoc(docRef, settings);
      fetchSettings();
      setShowSuccessModal(true);
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  };

  const handleUpdateDailyComboImages = async () => {
    const docRef = doc(db, 'DailyCombo', 'mia6x8Hb9656p8UQtdfL');
    try {
      await updateDoc(docRef, {
        correctImages: dailyComboImages,
      });
      fetchDailyComboImages();
      setShowSuccessModal(true);
    } catch (e) {
      console.error("Error updating DailyCombo images: ", e);
    }
  };

  const handleDeleteUser = async () => {
    const docRef = doc(db, 'DailyComboUsers', userToDelete); // Specify the collection and document to delete
    try {
      await deleteDoc(docRef);
      setUserToDelete(''); // Clear the input after deletion
      setShowSuccessModal(true); // Show success modal
    } catch (e) {
      console.error("Error deleting user: ", e);
    }
  };

  const closeModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <div id='refer' className="w-full flex flex-col space-y-4 h-[100vh] scroller pt-4 overflow-y-auto pb-[150px]">

      <h1 className="text-[20px] font-semibold mb-1">Set Default Values</h1>

      <div className="flex w-full flex-wrap gap-3">
        <div className='flex flex-col w-full sm:w-[49%] gap-1'>
          <label className='text-[13px] pl-1 pb-[2px] font-medium'>Cool down Time</label>
          <input
            type="number"
            name="coolDownTime"
            value={settings.coolDownTime}
            onChange={handleInputChange}
            placeholder="Cool Down Time"
            className="bg-[#4b4b4b] placeholder:text-[#b9b9b9] text-[#e0e0e0] placeholder:text-[12px] text-[13px] placeholder:font-light h-[55px] border-none outline-none rounded-[10px] flex items-center px-6"
          />
        </div>

        <div className='flex flex-col w-full sm:w-[49%] gap-1'>
          <label className='text-[13px] pl-1 pb-[2px] font-medium'>Tap Guru Boost Value</label>
          <input
            type="number"
            name="tappingGuru"
            value={settings.tappingGuru}
            onChange={handleInputChange}
            placeholder="Tapping Guru"
            className="bg-[#4b4b4b] placeholder:text-[#b9b9b9] text-[#e0e0e0] placeholder:text-[12px] text-[13px] placeholder:font-light h-[55px] border-none outline-none rounded-[10px] flex items-center px-6"
          />
        </div>

        <button onClick={handleUpdateSettings} className="bg-green-500 font-semibold text-[15px] rounded-[6px] w-[50%] sm:w-[200px] h-fit px-4 py-3 text-[#fff]">Update Settings</button>
      </div>

      <h1 className="text-[20px] font-semibold mb-1 mt-5">Daily Combo Images</h1>

      <div className="flex w-full flex-wrap gap-3">
        {dailyComboImages.map((image, index) => (
          <div className='flex flex-col w-full sm:w-[49%] gap-1' key={index}>
            <label className='text-[13px] pl-1 pb-[2px] font-medium'>Correct Image {index + 1}</label>
            <input
              type="text"
              value={image}
              onChange={(e) => handleImageChange(index, e.target.value)}
              placeholder={`Image URL ${index + 1}`}
              className="bg-[#4b4b4b] placeholder:text-[#b9b9b9] text-[#e0e0e0] placeholder:text-[12px] text-[13px] placeholder:font-light h-[55px] border-none outline-none rounded-[10px] flex items-center px-6"
            />
          </div>
        ))}
      </div>

      <button onClick={handleUpdateDailyComboImages} className="bg-green-500 font-semibold text-[15px] rounded-[6px] w-[50%] sm:w-[200px] h-fit px-4 py-3 text-[#fff]">Update Daily Combo Images</button>

      <h1 className="text-[20px] font-semibold mb-1 mt-5">Delete User from DailyComboUsers</h1>

      <div className='flex flex-col w-full sm:w-[49%] gap-1'>
        <label className='text-[13px] pl-1 pb-[2px] font-medium'>User ID to Delete</label>
        <input
          type="text"
          value={userToDelete}
          onChange={(e) => setUserToDelete(e.target.value)}
          placeholder="User ID"
          className="bg-[#4b4b4b] placeholder:text-[#b9b9b9] text-[#e0e0e0] placeholder:text-[12px] text-[13px] placeholder:font-light h-[55px] border-none outline-none rounded-[10px] flex items-center px-6"
        />
      </div>

      <button onClick={handleDeleteUser} className="bg-red-500 font-semibold text-[15px] rounded-[6px] w-[50%] sm:w-[200px] h-fit px-4 py-3 text-[#fff]">Delete User</button>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
          <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
          <div className="modal-container bg-[#595D65] w-11/12 md:max-w-md mx-auto rounded-[10px] shadow-lg z-50 overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end items-center pb-3">
                <div className="modal-close cursor-pointer z-50" onClick={closeModal}>
                  <IoCloseCircleSharp size={32} className='text-secondary' />
                </div>
              </div>
              <div className="flex justify-center items-center">
                <p>Settings have been updated successfully.</p>
              </div>
              <div className="flex justify-center pt-2">
                <button className="modal-close bg-blue-500 text-white p-2 px-6 rounded" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSettings;
