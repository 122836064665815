// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';  // Import Firestore and doc functions

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBEN3lALLn5UqjYMnApieqnOV-selIIJmA",
  authDomain: "claws-96176.firebaseapp.com",
  projectId: "claws-96176",
  storageBucket: "claws-96176.firebasestorage.app",
  messagingSenderId: "23108670500",
  appId: "1:23108670500:web:1386dea24583f9dcd5b4b1",
  measurementId: "G-68FF36CQTW",
};

// Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

// Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

// Initialize Firestore and export it
const db = getFirestore(firebase_app);

// Function to store startTime in Firestore
const setStartTime = async () => {
  const startTime = 1696896000; // October 10, 2024 in UNIX timestamp

  try {
    // Store startTime in the 'times' collection, 'leaderboardTimer' document
    await setDoc(doc(db, 'times', 'leaderboardTimer'), {
      startTime: startTime,
      timeLeft: 604800, // Time left for countdown (7 days in seconds)
    });
    console.log("startTime and timeLeft successfully written to Firestore.");
  } catch (error) {
    console.error("Error writing document: ", error);
  }
};

// Call the function to set the startTime (only do this once, or as needed)
setStartTime();

export { auth, db };
export default firebase_app;
